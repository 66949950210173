import { useLocation } from 'react-router-dom';
import logo from '../Images/logo.png';

const Header = () => {
    const location = useLocation();
    return (
        <>
        <div className="loader-wrap">
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>
            <header className="main-header">
                <a href="/" className="logo-holder"><img src={logo} alt="" /></a>
                <div className="search-button"><i className="far fa-search"></i></div>
                <div className="search-input"><input name="se" id="se" type="text" className="search" placeholder="Search.." /></div>
                <div className="sb-button"></div>

                <div className="nav-button-wrap">
                    <div className="nav-button"><span></span><span></span><span></span></div>
                </div>
                <div className="nav-holder main-menu">
                    <nav>
                        <ul>
                            <li>
                                <a href="/" className={`${location.pathname === '/' ? 'act-link' : ''}`}>Home</a>
                            </li>
                            <li>
                                <a href="products" className={`${location.pathname === '/products' ? 'act-link' : ''}`}>Products</a>
                            </li>
                            <li>
                                <a href="about" className={`${location.pathname === '/about' ? 'act-link' : ''}`}>Company bio</a>
                            </li>
                            <li>
                                <a href="blog" className={`${location.pathname === '/blog' ? 'act-link' : ''}`}>About Us</a>
                            </li>
                            <li>
                                <a href="contact" className={`${location.pathname === '/contact' ? 'act-link' : ''}`}>Contacts</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Header;