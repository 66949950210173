import apex_item from '../Images/bg/apex-item.jpg';
import me from '../Images/avatar/me.jpg'
import avatar1 from '../Images/avatar/1.jpg';
import wazone from '../Images/folio/warzone.jpg';
import black from '../Images/folio/black.png';
import apex from '../Images/bg/apex.jpg';
import Subscribe from './Subscribe';
import { useEffect, useState } from 'react';
import { userService } from '../services/user.service';
import SignUp from './SignUp';

const Products = () => {
    const [isLogin, setIsLogin] = useState(false);
    useEffect(() => {
        setIsLogin(userService.read())
    }, [])
    return (
        <>
        <div id="wrapper">
                <div className="content">
                    <div className="column-image">
                        <div className="bg"  style={{ backgroundImage: `url(${apex_item})`}}></div>
                        <div className="overlay"></div>
                        <div className="column-title">
                            <h2>Apex Legends</h2>
                            <h3>Please give me article</h3>
                        </div>
                        <div className="column-notifer">
                            <div className="scroll-down-wrap transparent_sdw">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll down  to Discover</span>
                            </div>
                        </div>
                        <div className="fixed-column-dec"></div>
                    </div>
                    <div className="column-wrapper single-content-section">
                        <div className="fixed-bottom-content">
                            <div className="content-nav-fixed">
                                <ul>
                                    <li>
                                        <a href="products-1" className="ln"><i className="fal fa-long-arrow-left"></i><span>Prev <strong>- Product</strong></span></a>
                                        <div className="content-nav_mediatooltip cnmd_leftside"><img  src={wazone}   alt=""/></div>
                                    </li>
                                    <li>
                                        <a href="products-2" className="rn"><span >Next <strong>- Product</strong></span> <i className="fal fa-long-arrow-right"></i></a>
                                        <div className="content-nav_mediatooltip cnmd_rightside"><img  src={black}   alt=""/></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                         <section className="single-content-section">
                            <div className="container small-container">
                                <div className="post fl-wrap fw-post single-post ">
                                    <h2><span>Blog post title</span></h2>
                                    <ul className="blog-title-opt">
                                        <li><a href="/">12 may 2017</a></li>
                                        <li> - </li>
                                        <li><a href="/">Interviews </a></li>
                                        <li><a href="/">Design</a></li>
                                        <li> - </li>
                                        <li><a href="/"><span className="author_avatar"> <img alt='' src={avatar1} width="50" height="50"/></span>Jane Kowalski</a></li>
                                    </ul>
                                    <div className="blog-media fl-wrap">
                                        <div className="single-slider-wrap">
                                            <div className="single-slider fl-wrap">
                                                <div className="swiper-container">
                                                    <div className="swiper-wrapper lightgallery">
                                                        <div className="swiper-slide hov_zoom"><img src={apex} alt=""/><a href="images/folio/1.jpg" className="box-media-zoom   popup-image"><i className="fal fa-search"></i></a></div>
                                                        <div className="swiper-slide hov_zoom"><img src={apex} alt=""/><a href="images/folio/1.jpg" className="box-media-zoom   popup-image"><i className="fal fa-search"></i></a></div>
                                                        <div className="swiper-slide hov_zoom"><img src={apex} alt=""/><a href="images/folio/1.jpg" className="box-media-zoom   popup-image"><i className="fal fa-search"></i></a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ss-slider-pagination_wrap">
                                                <div className="ss-slider-pagination"></div>
                                            </div>
                                            <div className="ss-slider-cont ss-slider-cont-prev color-bg"><i className="fal fa-long-arrow-left"></i></div>
                                            <div className="ss-slider-cont ss-slider-cont-next color-bg"><i className="fal fa-long-arrow-right"></i></div>
                                        </div>
                                    </div>
                                    <div className="blog-text fl-wrap">
                                        <div className="pr-tags fl-wrap">
                                            <span>Tags : </span>
                                            <ul>
                                                <li><a href="/">Design</a></li>
                                                <li><a href="/">Photography</a></li>
                                                <li><a href="/">Fasion</a></li>
                                            </ul>
                                        </div>
                                        <p style={{fontSize:'18px'}}>Bot features</p>
                                        <p> - Enemy tracking </p> 
                                        <p> - Anti recoil </p>
                                        <p> - Crouch spam  </p>
                                        <p> - Slide spam </p>
                                        <p> - Aim assist </p>
                                        <p> - Auto switch weapons </p>
                                        <p> - Rotational aim assist enhancement </p>        
                                    </div>
                                    <div className="post-author fl-wrap">
                                        <div className="author-img">
                                            <img alt='' src={me}/>	
                                        </div>
                                        <div className="author-content">
                                            <h5><a href="/">Customer A</a></h5>
                                            <p>This cheat is really good. Please purchase now. We support 6 months as free.</p>
                                            <div className="author-social">
                                                <ul>
                                                    <li><a href="/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="/" target="_blank"><i className="fab fa-vk"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="comments" className="single-post-comm fl-wrap">
                                        <h6 id="comments-title">Comments<span>( 2 )</span></h6>
                                        <ul className="commentlist clearafix">
                                            <li className="comment">
                                                <div className="comment-body">
                                                    <div className="comment-author">
                                                        <img alt='' src={avatar1} width="50" height="50"/>
                                                    </div>
                                                    <cite className="fn"><a href="/">Kevin</a></cite>
                                                    <div className="comment-meta">
                                                        <h6><a href="/">January 02, 2014 at 07:39 am</a> / <a className='comment-reply-link' href="/">Reply</a></h6>
                                                    </div>
                                                    <p>Awesome Cheat! I'm really happy to use this. Thanks very much</p>
                                                </div>
                                            </li>
                                            <li className="comment">
                                                <div className="comment-body">
                                                    <div className="comment-author">
                                                        <img alt='' src={avatar1} width="50" height="50"/>
                                                    </div>
                                                    <cite className="fn"><a href="/">Andy</a></cite>
                                                    <div className="comment-meta">
                                                        <h6><a href="/">January 22, 2014 at 03:19 am</a> / <a className='comment-reply-link' href="/">Reply</a></h6>
                                                    </div>
                                                    <p>Funny! This is really good bot. I'm very very happy to use this.</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="clearfix"></div>
                                        <div id="respond" className="clearafix">
                                            <h6 id="reply-title">Leave A Review</h6>
                                            <form  className="custom-form "   name="commentform">
                                                <fieldset>
                                                    <input type="text" name="name" id="name2" placeholder="Your Name *" value=""/>
                                                    <input type="text"  name="email" id="email2" placeholder="Email Address *" value=""/>
                                                    <textarea name="comments"  id="comments2" cols="40" rows="3" placeholder="Your Message:"></textarea>
                                                    <button className="btn float-btn" id="submit">Add Comment </button>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="min-footer fl-wrap content-animvisible">
                            <div className="container small-container">
                                <div className="footer-inner fl-wrap">
                                    <div className="policy-box">
                                        <span>&#169; Kotlis 2019  /  All rights reserved. </span>
                                    </div>
                                    <a href="/" className="to-top-btn to-top">Purchase Now </a>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
                <div className="share-wrapper">
                    <div className="share-container fl-wrap  isShare"></div>
                </div>
            </div>
            <div className="sb-overlay"></div>
            {
                isLogin ? (
                    <Subscribe />
                ) : (
                    <SignUp />
                )
            }
        </>
    )
}

export default Products;