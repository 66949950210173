import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './Component/Landing';
import Home from './Component/Home';
import Products from './Component/Products';
import About from './Component/About';
import Blog from './Component/Blog';
import Contact from './Component/Contact';
import Products2 from './Component/Products2';
import Products1 from './Component/Products1';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='' element={<Landing/>}>
          <Route path='' element={<Home/>}/>
          <Route path='products' element={<Products/>}/>
          <Route path='products-1' element={<Products1/>}/>
          <Route path='products-2' element={<Products2/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='blog' element={<Blog/>}/>
          <Route path='contact' element={<Contact/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
