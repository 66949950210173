import { useEffect, useState } from "react";
import { userService } from "../services/user.service";
import bg from '../Images/bg/news.jpg'
import Subscribe from "./Subscribe";
import SignUp from "./SignUp";

const Blog = () => {
    const [isLogin, setIsLogin] = useState(false);
    useEffect(() => {
        setIsLogin(userService.read())
    }, [])
    return (
        <>
        <div id="wrapper">
                <div className="content">
                    <div className="column-image">
                        <div className="bg" style={{ backgroundImage: `url(${bg})`}}></div>
                        <div className="overlay"></div>
                        <div className="column-title">
                            <h2>About Us</h2>
                            <h3></h3>
                        </div>
                        <div className="column-notifer">
                            <div className="scroll-down-wrap transparent_sdw">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll down  to Discover</span>
                            </div>
                        </div>
                        <div className="fixed-column-dec"></div>
                    </div>
                    <div className="column-wrapper  single-content-section">
                        <section className="single-content-section">
                            <div className="container small-container">
                                <div className="post fl-wrap fw-post">
                                    <h2><span>The Development Team Behind Only Cheats</span></h2>
                                    <div className="blog-text fl-wrap">
                                        <p>
                                        Our success at Only Cheats is driven by a team of seasoned developers with deep roots in the gaming industry and extensive expertise in building and working with the Source Engine. With a background in creating robust mods, tools, and cheats for Source Engine games, our developers bring a unique skill set that enables them to deliver cheats that are not only powerful but also highly optimized and undetectable.
                                        </p>
                                        <p>The Source Engine, known for its flexibility and versatility, provides an exceptional foundation for our team’s development skills. This experience allows us to engineer advanced features that adapt seamlessly to console environments, leveraging knowledge of rendering pipelines, physics systems, and AI integration. Our familiarity with the engine gives us insight into memory management, networking, and in-game mechanics, helping us design cheats that operate with efficiency and discretion. The same principles that apply to Source Engine optimization translate directly into high-performance cheats for consoles, ensuring smooth, realistic gameplay without compromising security.</p>                                        
                                    </div>
                                </div>
                                <div className="post fl-wrap fw-post">
                                    <h2><span>How We Use Computer Vision and AI to Redefine Aimbot Capabilities</span></h2>
                                    <div className="blog-text fl-wrap">
                                        <p>
                                        At Only Cheats, we leverage advanced computer vision and AI to deliver next-level aimbot capabilities. Our development team has crafted proprietary algorithms from the ground up, using techniques that eliminate the need for detectable external devices or software. Here’s how our tech works:
                                        </p>                                        
                                    </div>
                                    <h2><span>Computer Vision Detection</span></h2>
                                    <div className="blog-text fl-wrap">
                                        <p>
                                        Our computer vision system taps into the game’s visual output, analyzing and interpreting the environment to identify targets in real-time. Instead of relying on hardware or software that could be flagged, we use non-intrusive methods to “see” the game as a human player would. This innovative approach allows our cheats to identify players, analyze movement patterns, and track targets without risking detection.
                                        </p>                                        
                                    </div>
                                    <h2><span>AI-Driven Accuracy</span></h2>
                                    <div className="blog-text fl-wrap">
                                        <p>
                                        Once targets are recognized, our AI technology takes over, ensuring precision and control that feels both realistic and adaptive. Our AI algorithms are trained on deep learning models that mimic human aiming behavior, making its actions appear natural. This helps the cheat blend seamlessly with gameplay, avoiding the telltale signs of a “bot-like” aimbot. From adjusting to recoil to predicting enemy movement, our AI-driven precision offers unmatched performance without arousing suspicion.
                                        </p>                                        
                                    </div>
                                    <h2><span>Self-Learning Cheat Algorithms</span></h2>
                                    <div className="blog-text fl-wrap">
                                        <p>
                                        One of our standout features is self-learning capability. Through machine learning, our cheats continuously adapt to your gameplay style and any game updates, keeping your experience fresh and undetectable. The AI observes how you play and modifies its behavior to complement your style, enhancing both performance and security.
                                        </p>                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="min-footer fl-wrap content-animvisible">
                            <div className="container small-container">
                                <div className="footer-inner fl-wrap">
                                    <div className="policy-box">
                                        <span>&#169; Only Cheats 2024  /  All rights reserved. </span>
                                    </div>                                    
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
                <div className="share-wrapper">
                    <div className="share-container fl-wrap  isShare"></div>
                </div>
            </div>
            <div className="sb-overlay"></div>
            {
                isLogin ? (
                    <Subscribe />
                ) : (
                    <SignUp />
                )
            }
        </>
    )
}

export default Blog;