import { Outlet } from "react-router-dom";
import Header from "./Header";

const Landing = () => {
    return (
        <div id="main">
            <Header />
            <Outlet />
        </div>
    )
}
export default Landing;