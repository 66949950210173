import { useEffect, useState } from "react";
import SignUp from "./SignUp";
import { userService } from "../services/user.service";
import Subscribe from "./Subscribe";
import me from '../Images/avatar/aboutme.jpeg'
import avatar1 from '../Images/avatar/1.jpg';
import image from '../Images/bg/1.jpg'

const About = () => {
    const [isLogin, setIsLogin] = useState(false);
    useEffect(() => {
        setIsLogin(userService.read())
    }, [])
    return (
        <>
        <div id="wrapper">
                <div className="content">
                    <div className="column-image">
                        <div className="bg"  style={{ backgroundImage: `url(${me})`}}></div>
                        <div className="overlay"></div>
                        <div className="column-title">
                            <h2>Company bio</h2>
                            <h3>You should provide about that for you.</h3>
                        </div>
                        <div className="column-notifer">
                            <div className="scroll-down-wrap transparent_sdw">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll down  to Discover</span>
                            </div>
                        </div>
                        <div className="fixed-column-dec"></div>
                    </div>
                    <div className="column-wrapper">
                        <section id="sec1">
                            <div className="container small-container">
                                <div className="section-title fl-wrap">
                                    <div className="column-wrapper_text fl-wrap">
                                        <h3>Company Bio</h3>
                                        <p>Welcome to Only Cheats, your one-stop solution for high-quality, undetectable hacks on console gaming platforms. Our mission is simple: to deliver next-generation tools that empower gamers by unlocking their true potential, all while keeping security at the forefront. At Only Cheats, we strive to revolutionize the gaming experience, combining the latest advancements in computer vision and AI to bring you cutting-edge cheats that work seamlessly without the need for traditional external devices or invasive software.</p>
                                        <h3>Our Philosophy: Next-Level Innovation with Security in Mind</h3>
                                        <p>We understand the stakes in today's gaming environment. Traditional cheats often require hardware-based solutions like DMA devices or complex external software that can be easily flagged and detected. Our approach is different. We’re committed to providing an innovative and secure alternative that prioritizes user experience and security. By leveraging state-of-the-art computer vision and AI-driven technologies, we can bypass the need for cumbersome and detectable devices, making our solutions not only stealthy but also user-friendly.</p>
                                        <h3>How We Use Computer Vision and AI to Redefine Aimbot Capabilities</h3>
                                        <p>Our cheat solutions are powered by advanced computer vision technology, designed to analyze gameplay footage in real-time. Here’s how it works:</p>
                                        <h3>Computer Vision Detection</h3>
                                        <p>By tapping into the visual feed, our computer vision algorithms scan and recognize patterns, enemies, and other crucial elements in the game. This process involves identifying key features, such as player shapes, colors, or movements, without relying on a DMA device or other detectable hardware. The AI is trained to "see" the game environment as a human player would, enabling it to lock onto targets with remarkable accuracy.</p>
                                        <h3>AI-Assisted Precision</h3>
                                        <p>Once the computer vision system has identified a target, our custom AI takes over to manage aimbot precision. Our AI is crafted using deep learning techniques that simulate natural aiming patterns, making its actions appear realistic and avoiding the “bot-like” movement that typical cheats often exhibit. This deep learning approach lets our AI anticipate movement, adjust for recoil, and react instantly to changes in gameplay, offering seamless accuracy without arousing suspicion.</p>
                                        <h3>Self-Learning Algorithms</h3>
                                        <p>A key feature of our technology is its self-learning capability. Through machine learning, our cheats can adapt to individual player habits and game updates, ensuring that our tools remain effective over time. The AI observes and learns from your gameplay, tailoring the cheat behavior to feel natural and unobtrusive. This adaptability not only boosts performance but also minimizes the risk of detection by anti-cheat systems.</p>
                                        <h3>Why Choose Only Cheats?</h3>
                                        <p>Undetectable Solutions: Our cheats don’t rely on external software or devices that could be flagged. This keeps your account safe and allows you to play without fear of detection.
                                        User-Friendly Integration: We make it easy to get started with a system designed to be accessible for both novice and experienced users.</p>
                                        <p>AI-Driven Edge: By incorporating AI and computer vision, we provide a realistic, responsive experience that puts you in full control.</p>
                                        <p>Constant Innovation: At Only Cheats, we're always at the forefront of technology. Our dedicated team of developers continually refines our cheats to stay ahead of game updates and anti-cheat patches, ensuring that you’ll always have access to the latest, safest tools.</p>
                                        <p>With Only Cheats, you can experience gaming like never before, with powerful, reliable, and undetectable cheats that redefine what's possible in the console gaming world. Join us as we push the boundaries of gaming technology, setting new standards for performance and security.</p>                                       
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="sb-overlay"></div>
            {
                isLogin ? (
                    <Subscribe />
                ) : (
                    <SignUp />
                )
            }
        </>
    )
}

export default About;