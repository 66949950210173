import { useEffect, useState } from 'react';
import bg from '../Images/bg/discord.jpg'
import { userService } from '../services/user.service';
import Subscribe from './Subscribe';
import SignUp from './SignUp';

const Contact = () => {
    const [isLogin, setIsLogin] = useState(false);
    useEffect(() => {
        setIsLogin(userService.read())
    }, [])
    return (
        <>
            <div id="wrapper">
                <div className="content">
                    <div className="column-image">
                        <div className="bg" style={{ backgroundImage: `url(${bg})`}}></div>
                        <div className="overlay"></div>
                        <div className="column-title">
                            <h2>Contact Info</h2>
                            <h3></h3>
                        </div>
                        <div className="column-notifer">
                            <div className="scroll-down-wrap transparent_sdw">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll down  to Discover</span>
                            </div>
                        </div>
                        <div className="fixed-column-dec"></div>
                    </div>
                    <div className="column-wrapper ">
                        <div className="scroll-nav-wrap">
                            <nav className="scroll-nav scroll-init">
                                <ul>
                                    <li><a className="act-scrlink" href="#sec1">Details</a></li>
                                    <li><a href="#sec3">Say Hello</a></li>
                                </ul>
                            </nav>
                        </div>
                        <section id="sec1">
                            <div className="container small-container">
                                <div className="section-title fl-wrap">
                                    <h3>Contact Information</h3>
                                    <h4>Sed tempor iaculis massa faucibus feugiat. In fermentum facilisis massa</h4>
                                </div>
                                <div className="column-wrapper_item fl-wrap">
                                    <div className="column-wrapper_text fl-wrap">
                                        <p>If you’d like to talk about a project, our work or anything else then get in touch via Discord.</p>
                                        <div className="contact-details fl-wrap">
                                        </div>
                                        <a href="https://discord.gg/x8kWxRrW" className="custom-scroll-link btn fl-btn">Say Hello</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="sec-dec"></div>
                        <section id="sec3">
                            <div className="container small-container">
                                <div className="section-title fl-wrap">
                                    <h3>Get In Touch</h3>
                                    <h4>Sed tempor iaculis massa faucibus feugiat. In fermentum facilisis massa</h4>
                                </div>
                                <div className="column-wrapper_item fl-wrap">
                                    <div className="column-wrapper_text fl-wrap">
                                        <div id="contact-form" className="fl-wrap">
                                            <div id="message"></div>
                                            <form className="custom-form" action="php/contact.php" name="contactform" id="contactform">
                                                <fieldset>
                                                    <input type="text" name="name" id="name" placeholder="Your Name *" value="" />
                                                    <input type="text" name="email" id="email" placeholder="Email Address *" value="" />
                                                    <textarea name="comments" id="comments" cols="40" rows="3" placeholder="Your Message:"></textarea>
                                                    <button className="btn float-btn flat-btn color-bg" id="submit">Send Message </button>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="min-footer fl-wrap content-animvisible">
                            <div className="container small-container">
                                <div className="footer-inner fl-wrap">
                                    <div className="policy-box">
                                        <span>&#169; Only Cheats 2024  /  All rights reserved. </span>
                                    </div>                                    
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
                <div className="share-wrapper">
                    <div className="share-container fl-wrap  isShare"></div>
                </div>
            </div>
            <div className="sb-overlay"></div>
            {
                isLogin ? (
                    <Subscribe />
                ) : (
                    <SignUp />
                )
            }
        </>
    )
}

export default Contact;