import apex from '../Images/bg/apex.jpeg';
import wazone from '../Images/bg/wazone.jpg';
import black from '../Images/bg/black.jpg';
import SignUp from './SignUp';
import { useEffect, useState } from 'react';
import { userService } from '../services/user.service';
import Subscribe from './Subscribe';

const Home = () => {
    const [isLogin, setIsLogin] = useState(false);
    useEffect(() => {
        setIsLogin(userService.read())
    }, [])
    return (
        <>
            <div id="wrapper">
                <div className="content full-height">
                    <div className="media-container">
                        <div className="fs-slider-wrap fs-slider-det full-height fl-wrap">
                            <div className="fs-slider lightgallery full-height fl-wrap" data-mousecontrol2="true">
                                <div className="swiper-container  ">
                                    <div className="swiper-wrapper" >
                                        <div className="swiper-slide hov_zoom" data-fsslideropt1="Detail (you have to give me article) : <span>details</span>" data-fsslideropt2="Detail -1 : <span> Detail -2</span>" data-fsslideropt3="Detail : <span>Details</span>" data-fssurl="portfolio-single.html">
                                            <div className="fs-slider-item fl-wrap">
                                                <div className="bg" style={{ backgroundImage: `url(${apex})` }}></div>
                                                <div className="overlay"></div>
                                                <div className="fs-slider_align_title">
                                                    <h2><a href="/">Apex Legends</a></h2>
                                                    <p>Comming Soon </p>
                                                    <div className="clearfix"></div>
                                                    <a href="/" className="btn fl-btn">Purchase Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide hov_zoom" data-fsslideropt1="Detail (you have to give me article) : <span>details</span>" data-fsslideropt2="Detail -1 : <span> Detail -2</span>" data-fsslideropt3="Detail : <span>Details</span>" data-fssurl="portfolio-single.html">
                                            <div className="fs-slider-item fl-wrap">
                                                <div className="bg" style={{ backgroundImage: `url(${wazone})` }}></div>
                                                <div className="overlay"></div>
                                                <div className="fs-slider_align_title">
                                                    <h2><a href="/">Call of Duty Warzone</a></h2>
                                                    <p>Great Game </p>
                                                    <div className="clearfix"></div>
                                                    <a href="/" className="btn fl-btn">Purchase Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide hov_zoom" data-fsslideropt1="Detail (you have to give me article) : <span>details</span>" data-fsslideropt2="Detail -1 : <span> Detail -2</span>" data-fsslideropt3="Detail : <span>Details</span>" data-fssurl="portfolio-single.html">
                                            <div className="fs-slider-item fl-wrap">
                                                <div className="bg" style={{ backgroundImage: `url(${black})` }}></div>
                                                <div className="overlay"></div>
                                                <div className="fs-slider_align_title">
                                                    <h2><a href="/">Call of Duty Black</a></h2>
                                                    <p>Great Game</p>
                                                    <div className="clearfix"></div>
                                                    <a href="/" className="btn fl-btn">Purchase Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-slider-wrap_pagination hlaf-slider-pag"></div>
                        <div className="hero-slider_details_wrap">
                            <div className="hero-slider_details fl-wrap">
                                <ul>
                                    <li className="opt-one"></li>
                                    <li className="opt-two"></li>
                                    <li className="opt-three"></li>
                                </ul>
                            </div>
                            <a href="/" className="hero-slider_details_url"><i className="fal fa-chevron-right"></i></a>
                        </div>
                        <div className="hero-slider_control-wrap dark-gradient">
                            <div className="scroll-down-wrap transparent_sdw">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll down or  Swipe</span>
                            </div>
                            <div className="hero-slider_control_item">
                                <div className="hero-slider_control hero-slider-button-prev"><i className="fal fa-angle-left"></i></div>
                                <div className="hero-slider_control hero-slider-button-next"><i className="fal fa-angle-right"></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-counter_wrap">
                        <div className="swiper-counter">
                            <div id="current">1</div>
                            <div id="total"></div>
                        </div>
                    </div>
                </div>
                <div className="share-wrapper">
                    <div className="share-container fl-wrap  isShare"></div>
                </div>
            </div>
            <div className="sb-overlay"></div>
            {
                isLogin ? (
                    <Subscribe />
                ) : (
                    <SignUp />
                )
            }

        </>
    )
}
export default Home;