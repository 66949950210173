import { useState } from "react";
import { authService } from "../services/auth.service";

const SignUp = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = { email, password }

        if(showLogin){
            const response = await authService.login(payload);

            if(response.status === 200) {
                window.location.reload();
            } else {
                setAlert(true);
            }
        } else {
            const response = await authService.signup(payload);

            if (response.status === 200) {
                window.location.reload();
            } else {
                setAlert(true);
            }
        }
    }
    return (
        <>
            <div className="hiiden-sidebar-wrap outsb">
                <div className="sb-widget-wrap fl-wrap">
                    {
                        showLogin ? (
                            <h3>SignIn To Our Service</h3>
                        ) : (
                            <h3>Register To Our Service</h3>
                        )
                    }

                    {
                        alert && (
                            <p>User is already existed or password is wrong</p>
                        )
                    }
                    <div className="sb-widget  fl-wrap">
                        <div className="subcribe-form fl-wrap">
                            <form id="subscribe">
                                <input className="enteremail" name="email" placeholder="Your Email" spellCheck="false" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <input className="enteremail" name="password" placeholder="Your Password" spellCheck="false" type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <button type="button" onClick={handleSubmit} id="subscribe-button" className="subscribe-button">Submit</button>
                                <label htmlFor="subscribe-email" className="subscribe-message"></label>
                            </form>
                        </div>
                    </div>
                    {
                        showLogin ? (
                            <div style={{ color: 'white' }} onClick={()=>setShowLogin(false)}>If you are new, <span style={{ color: '#4040ff', fontSize: '16px' }}>Sign Up</span></div>
                        ) : (
                            <div style={{ color: 'white' }} onClick={()=>setShowLogin(true)}>If you have already registered, <span style={{ color: '#4040ff', fontSize: '16px' }}>Sign In</span></div>
                        )
                    }

                </div>
            </div>

            <div className="element">
                <div className="element-item"></div>
            </div>
        </>
    )
}

export default SignUp;